<template>
	<div>
		<div class="content">
			<!-- 麵包屑 -->
			<div class="breadcrumb">
				<el-breadcrumb separator="/"><el-breadcrumb-item>填写订单信息</el-breadcrumb-item></el-breadcrumb>
			</div>
			<div class="all">
				<div class="content">
					<div class="outsideAdress">
						<div class="adress">
							<div class="adressTop"><span class="words">工作地址</span></div>
							<div class="adressBottom">
								<div class="adressLeft">
									<table>
										<tr>
											<span class="words1">收 货 人 :</span>
											<span class="words2">{{ DefaultAdress.receiver ? DefaultAdress.receiver : '无' }}</span>
										</tr>
										<tr>
											<td>
												<el-row>
													<el-col :span="19">
														<span class="words1">联系方式 :</span>
														<span class="words2">{{ DefaultAdress.mobile ? DefaultAdress.mobile : '无' }}</span>
													</el-col>
													<el-col :span="5"><span class="words3 cursor" v-if="DefaultAdress.receiver" @click="updateAdress">修改地址</span></el-col>
												</el-row>
											</td>
										</tr>
										<tr>
											<span class="words1">收货地址 :</span>
											<span class="words2">{{ DefaultAdress.address ? DefaultAdress.address : '无' }}</span>
										</tr>
									</table>
								</div>
								<div class="adressRight">
									<el-button type="primary" icon="el-icon-location-outline" @click="conversion">切换地址</el-button>
									<el-button icon="el-icon-circle-plus-outline" @click="tianjiaAdressDia">添加地址</el-button>
								</div>
							</div>
						</div>
					</div>
					<!-- 卡片 -->
					<el-card class="my-25">
						<div slot="header" class="flex">
							<h3 class="pr-30">备注</h3>
							<span>订单金额与乙方根据具体需求同共协商而定</span>
						</div>
						<div class="textItem"><el-input v-model="requireState" placeholder="请输入你的需求信息，便于评估..."></el-input></div>
					</el-card>
					<!-- 服务内容 -->
					<div class="serverInfo">
						<div class="header">
							<div class="item col1 colText">服务内容</div>
							<div class="item col3">租赁数量</div>
							<div class="item col6">租赁时长</div>
							<div class="item col3">押金</div>
							<div class="item col3">租金</div>
							<div class="item col4">实付</div>
						</div>
						<div class="list">
							<div class="item flex col1">
								<div class="flex ai-center p-10">
									<div class="listImg"><img :src="photoURL + serverInfObj.img" alt /></div>
									<div>
										<div class="brandTop words1" style="font-size: 20px;font-weight: 600;">{{ serverInfObj.name }}</div>
										<div class="brandTop">{{ serverInfObj.brandName }}</div>
										<div class="brandTop">{{ serverInfObj.number }}</div>
									</div>
								</div>
							</div>
							<div class="item col3">{{ serverInfObj.goodsNum }}</div>
							<div class="item col6">
								<span>{{ serverInfObj.rentStartTime + ' 至 ' + serverInfObj.rentEndTime }}</span>
								<span>共：{{ getDiffDay(serverInfObj.rentStartTime, serverInfObj.rentEndTime) }}天</span>
							</div>
							<div class="item col3">¥{{ serverInfObj.deposit }}</div>
							<div class="item col3">¥{{ serverInfObj.price }}</div>
							<div class="item col4 blueColor">{{ serverInfObj.price != 0 ? '￥' + Totalprice : '洽谈' }}</div>
						</div>
					</div>
					<!-- 提交訂單 -->
					<div class="orderSubmit mt-25 mb-40">
						<div class="orderSubmitl"></div>
						<div class="orderSubmitr">
							<table>
								<tr>
									<span class="words1 words2">实付款：</span>
									<span class="price">{{ serverInfObj.price != 0 ? '￥' + Totalprice : '洽谈' }}</span>
								</tr>
								<tr v-if="DefaultAdress.addressFull">
									<span class="words1 words2">寄送至:</span>
									<span class="words1">{{ DefaultAdress.addressFull }}</span>
								</tr>
								<tr v-if="DefaultAdress.receiver">
									<span class="words1 words2">{{ DefaultAdress.receiver }}:</span>
									<span class="words1">{{ DefaultAdress.mobile }}</span>
								</tr>
								<tr><el-button type="primary" @click="orderSubmit">提交订单</el-button></tr>
							</table>
						</div>
					</div>
				</div>
			</div>
			<!-- 修改地址彈框 -->
			<div class="updateAdressDia">
				<el-dialog @close="chooseupdateAdressDia" title="修改收货地址" :visible.sync="updateAdressDia" width="30%">
					<el-form :model="form" :rules="formRules">
						<el-form-item label="收货人姓名" :label-width="formLabelWidth" prop="receiver">
							<el-input v-model="form.receiver" placeholder="请输入收货人姓名"></el-input>
						</el-form-item>
						<el-form-item label="手机号码" :label-width="formLabelWidth" prop="mobile">
							<el-input v-model="form.mobile" placeholder="请输入手机号码" :maxlength="11"></el-input>
						</el-form-item>
						<el-form-item label="地址信息" :label-width="formLabelWidth" prop="Provinces">
							<el-cascader
								v-model="Provinces"
								placeholder="请选择省/市/区"
								@change="CityChange"
								:props="{ value: 'id', label: 'name', children: 'child' }"
								:options="cityOptions"
							></el-cascader>
						</el-form-item>
						<el-form-item label="详细地址" :label-width="formLabelWidth" prop="address">
							<el-input v-model="form.address" placeholder="请输入详细地址"></el-input>
						</el-form-item>
						<el-form-item label="邮政编码" :label-width="formLabelWidth"><el-input v-model="form.postalCode" placeholder="请输入邮政编码"></el-input></el-form-item>
					</el-form>
					<div slot="footer" class="dialog-footer">
						<el-button @click="chooseupdateAdressDia">取 消</el-button>
						<el-button type="primary" @click="updateadresSubmit">提交</el-button>
					</div>
				</el-dialog>
			</div>
			<!-- 添加地址彈框 -->
			<div class="AdressDia">
				<el-dialog title="添加收货地址" :visible.sync="AdressDia" width="30%">
					<el-form :model="form" :rules="formRules">
						<el-form-item label="收货人姓名" :label-width="formLabelWidth" prop="receiver">
							<el-input v-model="form.receiver" placeholder="请输入收货人姓名"></el-input>
						</el-form-item>
						<el-form-item label="手机号码" :label-width="formLabelWidth" prop="mobile">
							<el-input v-model="form.mobile" placeholder="请输入手机号码" :maxlength="11"></el-input>
						</el-form-item>
						<el-form-item label="地址信息" :label-width="formLabelWidth" prop="address">
							<el-cascader
								v-model="Provinces"
								placeholder="请选择省/市/区"
								@change="CityChange"
								:props="{ value: 'name', label: 'name', children: 'child' }"
								:options="cityOptions"
							></el-cascader>
						</el-form-item>
						<el-form-item label="详细地址" :label-width="formLabelWidth" prop="address">
							<el-input v-model="form.address" placeholder="请输入详细地址"></el-input>
						</el-form-item>
						<el-form-item label="邮政编码" :label-width="formLabelWidth"><el-input v-model="form.postalCode" placeholder="请输入邮政编码"></el-input></el-form-item>
						<el-form-item label="默认地址" :label-width="formLabelWidth">
							<el-switch v-model="isDefault" active-color="#66b1ff" active-value="1" inactive-value="0"></el-switch>
						</el-form-item>
					</el-form>
					<div slot="footer" class="dialog-footer">
						<el-button @click="AdressDia = false">取 消</el-button>
						<el-button type="primary" @click="tianjiaadresSubmit">添加</el-button>
					</div>
				</el-dialog>
			</div>
			<!--  切换地址 -->
			<div class="addAdressDia">
				<el-dialog title="选择收货地址" :visible.sync="addAdressDia" width="32%">
					<div class="goodsadress mb-20">
						<table class="p-10" v-for="(item, index) in addressList" :key="index" @click="choose(index)" :class="{ blueColor: currentIndex === index }">
							<tr>
								<span class="words1">收 货 人 :</span>
								<span class="words2">{{ item.receiver }}</span>
							</tr>
							<tr>
								<span class="words1">联系方式 :</span>
								<span class="words2">{{ item.mobile }}</span>
							</tr>
							<tr>
								<el-row>
									<el-col :span="19">
										<span class="words1">收货地址 :</span>
										<span class="words2">{{ item.addressFull }}</span>
									</el-col>
									<el-col :span="5"><span class="words3 cursor" v-if="item.isDefault == 1" @click="chooseDefault(item)">默认地址</span></el-col>
								</el-row>
							</tr>
						</table>
						<el-empty description="请先去添加地址" v-if="addressList.length==0"></el-empty>
					</div>
					<div slot="footer" class="dialog-footer">
						<el-button @click="addAdressDia = false">取 消</el-button>
						<el-button type="primary" :disabled="addressList.length==0" @click="addAdressDiaClick">确定</el-button>
						<el-button type="danger" @click="deleteAddress">删除选中地址</el-button>
					</div>
				</el-dialog>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			params: null,
			isDefault: '0', //默认地址
			info: this.$route.query.info,
			Provinces: [],
			value1: '',
			input: '',
			currentIndex: 0,
			modifyTime: '', //要求完成时间
			requireState: '', //需求描述
			cityOptions: [],
			form: {
				// 地址标签邮编非必须
				id: '',
				isDefault: 0,
				receiver: '',
				mobile: '',
				province: '',
				city: '',
				district: '',
				address: '',
				postalCode: '',
				tag: ''
			},
			AdressDia: false,
			serverInfo: {}, //后台返回的内容
			serverInfObj: {},
			DefaultAdress: {},
			addressList: [],
			updateAdressDia: false,
			addAdressDia: false,
			formLabelWidth: '100px',
			updateAdressForm: {
				name: ''
			},
			formRules: {
				receiver: [
					{
						required: true,
						message: '请输入收货人姓名',
						trigger: 'blur'
					}
				],
				mobile: [
					{
						required: true,
						message: '请输入手机号码',
						trigger: 'blur'
					}
				],
				addressFull: [
					{
						required: true,
						message: '请输入详细地址',
						trigger: 'blur'
					}
				],
				Provinces: [
					{
						required: true,
						message: '请输入地址信息',
						trigger: 'blur'
					}
				],
				address: [
					{
						required: true,
						message: '请输入详细地址',
						trigger: 'blur'
					}
				]
			},
		};
	},
	computed: {
		Totalprice: function() {
			let num = this.serverInfObj.price * this.serverInfObj.goodsNum * this.getDiffDay(this.serverInfObj.rentStartTime, this.serverInfObj.rentEndTime);
			let num2 = this.serverInfObj.deposit * this.serverInfObj.goodsNum;
			return num + num2;
		}
	},
	methods: {
		// 获取默认地址
		getDefaultAdress() {
			this.form ={};
			this.$get('/user-api/mall/address/default-query').then(data => {
				this.DefaultAdress = data.data;
			});
		},
		CityChange(value) {
			this.form.province = value[0];
			this.form.city = value[1];
			this.form.district = value[2];
		},
		// 修改地址
		updateAdress() {
			this.form = Object.assign({}, this.DefaultAdress);
			this.updateAdressDia = true;
			this.getProvince(1);
		},
		// 获取省市区地址
		getProvince(e) {
			// 获取省市区地址
			this.$get('base-api/area/tree').then(data => {
				this.cityOptions = this.getTreeData(data.data);
				if(e!=1) return
				this.$nextTick(() => {
					let i, j, k;
					for (i = 0; i < this.cityOptions.length; i++) {
						if (this.cityOptions[i].name === this.form.province) {
							this.$set(this.Provinces, 0, this.cityOptions[i].id);
							break;
						}
					}

					for (j = 0; j < this.cityOptions[i].child.length; j++) {
						if (this.cityOptions[i].child[j].name === this.form.city) {
							this.$set(this.Provinces, 1, this.cityOptions[i].child[j].id);
							break;
						}
					}

					for (k = 0; k < this.cityOptions[i].child[j].child.length; k++) {
						if (this.cityOptions[i].child[j].child[k].name === this.form.district) {
							this.$set(this.Provinces, 2, this.cityOptions[i].child[j].child[k].id);
							break;
						}
					}
				});
			});
		},
		// 添加地址弹框
		tianjiaAdressDia() {
			this.form = {};
			this.Provinces = [];
			this.getProvince(2);
			this.AdressDia = true;
		},
		// 提交添加地址
		tianjiaadresSubmit() {
			// console.log(+this.isDefault,'hhj')
			this.form.isDefault = this.isDefault;
			this.$postJson('/user-api/mall/address/save-or-update', this.form).then(data => {
				if (data.code == 1000) {
					this.$message({
						message: '添加成功',
						type: 'success'
					});
					this.AdressDia = false;
					this.getDefaultAdress();
				} else {
					this.$message(data.msg);
				}
			});
		},
		// 关闭修改地址弹窗
		chooseupdateAdressDia() {
			this.Provinces = [];
			// this.form = {};
			// this.form.province = '';
			// this.form.city ='';
			// this.form.district = '';
			this.updateAdressDia = false;
		},
		// 递归判断列表，把最后的children设为undefined
		getTreeData(data) {
			for (var i = 0; i < data.length; i++) {
				if (data[i].child.length < 1) {
					// children若为空数组，则将children设为undefined
					data[i].child = undefined;
				} else {
					// children若不为空数组，则继续 递归调用 本方法
					this.getTreeData(data[i].child);
				}
			}
			return data;
		},
		// 提交修改收货人地址
		updateadresSubmit() {
			this.$postJson('/user-api/mall/address/save-or-update', this.form).then(data => {
				if (data.code == 1000) {
					this.$message({
						message: '提交修改成功',
						type: 'success'
					});
					this.updateAdressDia = false;
					this.DefaultAdress = this.form;
				} else {
					this.$message(data.msg);
				}
			});
		},
		//删除地址
		deleteAddress(e) {
			this.$post('/user-api/mall/address/delete', {
				id: this.addressList[this.currentIndex].id
			}).then(data => {
				if (data.code === 1000) {
					this.$message({
						message: '删除成功',
						type: 'success'
					});
					this.getAddressList();
					this.getDefaultAdress();
				} else {
					this.$message(data.message);
				}
			});
		},
		// 选择收货地址
		choose(index) {
			this.currentIndex = index;
		},
		// 切换地址
		conversion() {
			this.addAdressDia = true;
			this.getAddressList();
		},
		//获取地址列表
		getAddressList() {
			this.$get('/user-api/mall/address/list').then(data => {
				this.addressList = data.data;
				this.currentIndex = 0;
			});
		},

		// 确认
		addAdressDiaClick() {
			this.addAdressDia = false;
			this.DefaultAdress = this.addressList[this.currentIndex];
			this.form.id = this.addressList[this.currentIndex].id;
		},
		// 选择默认地址
		chooseDefault(item) {
			this.$post('/user-api/mall/address/default-set', {
				id: item.id
			}).then(data => {
				if (data.code === 1000) {
					this.$message({
						message: '设置成功',
						type: 'success'
					});
					this.DefaultAdress.receiver = item.receiver;
					this.mobile.receiver = item.mobile;
					this.address.receiver = item.address;
					window.location.reload();
					// this.getDefaultAdress();
				} else {
					this.$message(data.message);
				}
			});
		},
		// 提交订单
		orderSubmit() {
			if (this.form.id) {
				const orderForm = {
					addressId: this.form.id,
					remark: this.requireState,
					sku: [
						{
							skuId: this.serverInfObj.skuId,
							quantity: this.serverInfObj.goodsNum,
							endTime: this.serverInfObj.rentEndTime,
							startTime: this.serverInfObj.rentStartTime
						}
					]
				};
				if(!this.form.id){
					this.$message.error('地址不能为空');
					return
				}
				this.$postJson('/shop-api/mall/rent-order/create', orderForm).then(data => {
					if (data.code == 1000) {
						this.$message({
							message: '提交成功',
							type: 'success'
						});
						this.$router.push({
							path: '/orderSubmit'
						});
					} else {
						this.$message.error(data.msg);
					}
				});
			} else {
				this.$message.warning('请选择地址');
			}
		},
		getDiffDay(date_1, date_2) {
			// 计算两个日期之间的差值
			let totalDays, diffDate;
			let myDate_1 = Date.parse(date_1);
			let myDate_2 = Date.parse(date_2);
			// 将两个日期都转换为毫秒格式，然后做差
			diffDate = Math.abs(myDate_1 - myDate_2); // 取相差毫秒数的绝对值

			totalDays = Math.floor(diffDate / (1000 * 3600 * 24)); // 向下取整
			// console.log(totalDays)

			return totalDays + 1; // 相差的天数
		}
	},
	created() {
		this.serverInfObj = JSON.parse(this.$route.query.searchInfo);
		console.log(this.serverInfObj);
		// // console.log(this.serverInfObj,'this.serverInfObj')
		this.modifyTime = this.serverInfObj.modifyTime;
	},
	mounted() {
		this.getDefaultAdress();
	}
};
1;
</script>

<style lang="scss" scoped>
* {
	letter-spacing: 1px !important;

	.content {
		background-color: #f5f5f5;
		width: 1440px;
		margin: 0 auto;

		.breadcrumb {
			letter-spacing: 1px;
			width: 1440px;
			height: 40px;
			color: #8c8c8c;
			background-color: #efeff4;
			margin: 0 auto;
			padding: 0px 15px;

			.el-breadcrumb {
				display: flex;
				align-items: center;
				height: 40px;
			}
		}

		.all {
			margin: 0 auto;

			.content {
				letter-spacing: 1px;
				font-size: 14px;
				font-weight: 400;
				color: #595959;
				margin: 0 auto;
				background-color: white;

				.adress {
					background-color: #efeff4;
					margin-top: 15px;

					.adressTop {
						line-height: 40px;
						height: 40px;
						font-size: 16px;
						font-weight: bolder;
						color: #262626;
						padding-left: 15px;
					}

					.adressBottom {
						display: flex;
						justify-content: space-between;
						padding-bottom: 15px;

						.words2 {
							padding-left: 10px;
							color: #262626;
						}

						.words3 {
							color: #4a99f6;
						}

						.adressLeft {
							flex: 1;
							border-right: 1px solid #dddddd;

							table {
								width: 100%;

								tr {
									text-indent: 12px;
									height: 20px;

									td {
										height: 32px;
									}
								}
							}
						}

						.adressRight {
							text-align: center;
							display: flex;
							justify-content: center;
							align-items: center;
							width: 380px;
						}
					}
				}

				.table {
					margin: 20px 0;
					width: 100%;
					height: 54px;
					font-size: 14px;
					font-weight: 500;
					color: #333;
					border: 1px solid #e6f7ff;
					background-color: #e6f7ff;

					table {
						tr {
							td {
							}
						}
					}
				}

				.serverInfo {
					.col1 {
						flex: 1;
					}

					.col2 {
						width: 360px;
					}

					.col3 {
						width: 120px;
					}

					.col4 {
						width: 140px;
					}
					.col4 {
						width: 180px;
					}
					.col5 {
						width: 220px;
					}
					.col6 {
						width: 260px;
					}

					.colText {
						text-align: left !important;
						padding-left: 15px;

						span {
							padding-left: 15px;
							color: #4a99f6;
						}
					}

					.header {
						margin-bottom: 20px;
						border: 1px solid #bae7ff;
						background-color: #e6f7ff;
						display: flex;
						color: #595959;

						.item {
							text-align: center;
							line-height: 54px;
							height: 54px;
						}
					}

					.list {
						display: flex;

						.item {
							text-align: center;
							height: 148px;
							line-height: 148px;
							border-top: 1px solid #dddddd;
							border-right: 1px solid #dddddd;
							border-bottom: 1px solid #dddddd;

							&:first-child {
								border-left: 1px solid #dddddd;
							}

							.listImg {
								width: 100px;
								height: 100px;
								margin-right: 15px;

								img {
									border-radius: 8px;
									border: 1px solid #dddddd;
									height: 100%;
									width: 100%;
								}
							}

							.brandTop {
								height: 30px;
								text-align: left;
								line-height: 30px;
							}
						}
					}
				}

				.orderSubmit {
					display: flex;
					background-color: #eaf4fa;
					height: 260px;
					border: 1px solid #bae7ff;

					.orderSubmitl {
						flex: 1;
					}

					.orderSubmitr {
						width: 500px;
						display: flex;
						justify-content: center;
						align-items: center;

						table {
							tr {
								height: 35px;
								line-height: 35px;

								.price {
									color: #4a99f6;
									font-size: 28px;
									font-weight: 500;
								}

								.words1 {
									font-weight: 400;
									color: #262626;
								}

								.el-button--primary {
									width: 280px;
									height: 60px;
								}
							}
						}
					}
				}
			}
		}
	}
}

::v-deep.addAdressDia {
	.el-dialog__body {
		padding: 0 20px;
	}

	.adddressTop {
		border: 1px solid #a4a9b0;

		table {
			margin-top: 10px;
			width: 100%;

			tr {
				text-indent: 12px;
				height: 30px;
				line-height: 30px;

				:first-child(1) {
					margin-top: 20px;
				}

				.words2 {
					padding-left: 10px;
					color: #262626;
				}

				.words3 {
					color: #4a99f6;
				}
			}
		}
	}

	.goodsadress {
		.blueColor {
			border: 1px solid #4a99f6;
		}

		table {
			border: 1px solid #a4a9b0;
			margin-top: 10px;
			width: 100%;

			tr {
				text-indent: 12px;
				height: 30px;

				.words1 {
					font-weight: 400;
					color: #262626;
				}

				.words2 {
					padding-left: 10px;
					color: #262626;
				}

				.words3 {
					color: #4a99f6;
				}
			}
		}
	}
}

// 組件裡的樣式
::v-deep .el-dialog__footer {
	text-align: center;
}

::v-deep .el-dialog__header {
	background-color: #f8f8f8;
	letter-spacing: 1px;
	color: #333333;
	font-size: 20px;
}

::v-deep .textItem .el-input .el-input__inner {
	border: none;
}

::v-deep .el-card__header {
	background-color: #efeff4;
}

::v-deep {
	.el-cascader {
		width: 100%;
	}
}
</style>
